
/* #####  Fault Tree Static Data  ##### */

export const dataTree = [
  {
    id: 1,
    text: 'Plant Under Perfermance',
    diamond: false,
    children: [
      {
        id: 2,
        text: 'PV Module',
        diamond: false,
        children: [
          {
            id: 3,
            text: 'Bypass Diode',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Damage bypass diode',
                diamond: false,
              }
            ]
          },
          {
            id: 3,
            text: 'Hot spot',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Cell damage',
                diamond: false,
              }
            ]
          },
          {
            id: 3,
            text: 'Short Circuit/PID',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'No negative terminal earthing',
                diamond: false,
              }, {
                id: '4',
                text: 'Non PID resistive module',
                diamond: false,
              }
            ]
          },
          {
            id: 3,
            text: 'Module Failure',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Module damage',
                diamond: false,
              }
            ]
          }
        ]
      },
      {
        id: 5,
        text: 'Cables',
        diamond: false,
        children: [
          {
            id: 3,
            text: 'DC cables',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Higher cable loss',
                diamond: false,
              },
              {
                id: '4',
                text: 'Riso',
                diamond: false,
              },
              {
                id: '4',
                text: 'Lose contact of MC4',
                diamond: false,
              }
            ]
          },
          {
            id: 3,
            text: 'AC cables',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Higher cable loss',
                diamond: false,
              },
              {
                id: '4',
                text: 'Higher Riso',
                diamond: false,
              }
            ]
          }
        ]
      },
      {
        id: 8,
        text: 'Connectors',
        diamond: false,
        children: [
          {
            id: 3,
            text: 'MC4 or MC4 equivalent and cable lugs',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Riso - DC string cable',
                diamond: false,
              }, {
                id: '4',
                text: 'Poor contact of DC main cable lugs',
                diamond: false,
              }
            ]
          }
        ]
      },
      {
        id: 11,
        text: 'Soiling',
        diamond: false,
        children: [
          {
            id: 3,
            text: 'Cleaning cycle',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Electrical under performance',
                diamond: false,
              }
            ]
          }
        ]
      },
      {
        id: 14,
        text: 'Shading loss',
        diamond: false,
        children: [
          {
            id: 3,
            text: 'Mismatch with PVSYST',
            diamond: false,
            children: [
              {
                id: '4',
                text: 'Shadow from adjacent tress',
                diamond: false,
              }
            ]
          }
        ]
      }
    ]
  }
]



export const headerData = [
  {
    country: "India",
    partnersName: [
      {
        name: "Lumino",
        blocks: [
          {
            name: "Bhajanghat",
            details: [
              {
                header: "Details",
                subHeader: [
                  "PorjectOverView",
                  "GenerationSummery",
                  "PrecriptiveModel",
                ],
              },
              {
                header: "DiagnosticModel",
                subHeader: ["DetailSummery", "LossFlowDiagram"],
              },
              {
                header: "Inverters",
                subHeader: ["InverterEfficiency"],
              },
              {
                header: "Inverter1",
                subHeader: [
                  "SCB/SMB1",
                  "SCB/SMB2",
                  "SCB/SMB3",
                  "SCB/SMB4",
                  "SCB/SMB5",
                  "SCB/SMB6",
                  "SCB/SMB7",
                  "SCB/SMB8",
                  "SCB/SMB9",
                  "SCB/SMB10",
                  "SCB/SMB11",
                ],
              },
              {
                header: "Inverter2",
                subHeader: [
                  "SCB/SMB1",
                  "SCB/SMB2",
                  "SCB/SMB3",
                  "SCB/SMB4",
                  "SCB/SMB5",
                  "SCB/SMB6",
                  "SCB/SMB7",
                  "SCB/SMB8",
                  "SCB/SMB9",
                  "SCB/SMB10",
                  "SCB/SMB11",
                ],
              },
              {
                header: "Inverter3",
                subHeader: [
                  "SCB/SMB1",
                  "SCB/SMB2",
                  "SCB/SMB3",
                  "SCB/SMB4",
                  "SCB/SMB5",
                  "SCB/SMB6",
                  "SCB/SMB7",
                  "SCB/SMB8",
                  "SCB/SMB9",
                  "SCB/SMB10",
                  "SCB/SMB11",
                ],
              },
              {
                header: "Inverter4",
                subHeader: [
                  "SCB/SMB1",
                  "SCB/SMB2",
                  "SCB/SMB3",
                  "SCB/SMB4",
                  "SCB/SMB5",
                  "SCB/SMB6",
                  "SCB/SMB7",
                  "SCB/SMB8",
                  "SCB/SMB9",
                  "SCB/SMB10",
                  "SCB/SMB11",
                ],
              },
            ],
          },
        ],
      },
    ],
  },
  // , {
  //     country: "Uk",
  //     partnersName: ["uk_Project1", "uk_Project2", "uk_Project3", "uk_Project4"],
  //     block: ["uk_block1", "uk_block2"]

  // }, {
  //     country: "France",
  //     partnersName: ["Project1", "Project2", "Project3", "Project4"],
  //     block: ["block1", "block2"],

  // }, {
  //     country: "India",
  //     partnersName: ["Italy", "Project2", "Project3", "Project4"],
  //     block: ["block1", "block2"],

  // }, {
  //     country: "Thailand",
  //     partnersName: ["Project1", "Project2", "Project3", "Project4"],
  //     block: ["block1", "block2"],

  // }
];

export const B1_Inv1_smbs=[
  {url: "/dashboard/india/diagonistic/inverter1/heatmap-diagram",value:"HeatMap Diagram"},

{ url: "/dashboard/india/diagonistic/inverter1/SCBSMB1",value:"SMB 01"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB2",value:"SMB 02"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB3",value:"SMB 03"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB4",value:"SMB 04"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB5",value:"SMB 05"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB6",value:"SMB 06"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB7",value:"SMB 07"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB8",value:"SMB 08"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB9",value:"SMB 09"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB10",value:"SMB 10"},
  {url: "/dashboard/india/diagonistic/inverter1/SCBSMB11",value:"SMB 11"},
  // {url: "/dashboard/india/diagonistic/inverter1/SCBSMB12",value:"SMB12"},
]

export const B1_Inv2_smbs=[
  {url: "/dashboard/india/diagonistic/inverter2/heatmap-diagram",value:"HeatMap Diagram"},

  { url: "/dashboard/india/diagonistic/inverter2/SCBSMB1",value:"SMB 01"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB2",value:"SMB 02"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB3",value:"SMB 03"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB4",value:"SMB 04"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB5",value:"SMB 05"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB6",value:"SMB 06"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB7",value:"SMB 07"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB8",value:"SMB 08"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB9",value:"SMB 09"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB10",value:"SMB 10"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB11",value:"SMB 11"},
    {url: "/dashboard/india/diagonistic/inverter2/SCBSMB12",value:"SMB 12"},
  ]

  export const B2_Inv3_smbs=[
    {url: "/dashboard/india/diagonistic/inverter3/heatmap-diagram",value:"HeatMap Diagram"},

    { url: "/dashboard/india/diagonistic/inverter3/SCBSMB1",value:"SMB 01"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB2",value:"SMB 02"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB3",value:"SMB 03"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB4",value:"SMB 04"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB5",value:"SMB 05"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB6",value:"SMB 06"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB7",value:"SMB 07"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB8",value:"SMB 08"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB9",value:"SMB 09"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB10",value:"SMB 10"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB11",value:"SMB 11"},
      {url: "/dashboard/india/diagonistic/inverter3/SCBSMB12",value:"SMB 12"},
    ]

    export const B2_Inv4_smbs=[
      {url: "/dashboard/india/diagonistic/inverter4/heatmap-diagram",value:"HeatMap Diagram"},
      { url: "/dashboard/india/diagonistic/inverter4/SCBSMB1",value:"SMB 01"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB2",value:"SMB 02"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB3",value:"SMB 03"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB4",value:"SMB 04"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB5",value:"SMB 05"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB6",value:"SMB 06"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB7",value:"SMB 07"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB8",value:"SMB 08"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB9",value:"SMB 09"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB10",value:"SMB 10"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB11",value:"SMB 11"},
        {url: "/dashboard/india/diagonistic/inverter4/SCBSMB12",value:"SMB 12"},
      ]


